import paywallActivationContent from './paywall-activation-content.yml';
import acpPaywallActivationCtrl from './acp-paywall-activation-ctrl';
import acpPaywallActivationDirective from './acp-paywall-activation-directive';
// import acpComponentActivation from 'components/activation';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.paywall.activation
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-paywall-activation defer="some $q" parent-type="moveMoney or checks"></acp-paywall-activation>
 * ```
 *
 * ```js
 * angular.module('acp.component.paywall-activation.example', [
 *   require('acp.component.paywall-activation').name
 * ]);
 * ```
 */
const component = ng
  .module('acp.component.paywall-activation', [acpCore.name])
  .directive('acpPaywallActivation', acpPaywallActivationDirective)
  .controller('AcpActivationPaywallComponentCtrl', acpPaywallActivationCtrl)
  .run(
    /*@ngInject*/ function (contentSectionCache) {
      contentSectionCache.put(
        'components/paywall-activation',
        paywallActivationContent
      );
    }
  );

export default component;
