// @ngInject
function AcpActivationPaywallComponentCtrl($scope, acpCoreDispatcher) {
  $scope.$onValue(acpCoreDispatcher.activation.complete, function() {
    $scope.defer.resolve();
  });

  $scope.showSecurityCheck = $scope.parentType === 'debitCardTransfers';

  $scope.hideSecurityCheck = function() {
    $scope.showSecurityCheck = false;
  };
}

export default AcpActivationPaywallComponentCtrl;
