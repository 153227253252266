import acpPaywallActivationTemplate from './templates/acp-paywall-activation.html';
// @ngInject
function acpPaywallActivationDirective() {
  return {
    restrict: 'E',
    scope: {
      defer: '=',
      parentType: '@'
    },
    controller: 'AcpActivationPaywallComponentCtrl',
    template: acpPaywallActivationTemplate
  };
}

export default acpPaywallActivationDirective;
